import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages/home";
import "./assets/css/responsive.css";
import MakeBioData from "./pages/makeBioData";
import Templates from "./pages/templates";
import Template from "./pages/templates/Demotempllate";
import Term from "./components/term";
import ErroPage from "./pages/errorPage";
import Blog from "./components/Blog/blog";
import Policy from "./components/Policy";

const App = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description"
          content="BiodataMakers helps you create professional biodata online. Download templates, customize your biodata, and get started with your job search or marriage biodata." />
        <meta name="keywords"
          content="biodata maker,create biodata, create biodata, biodata templates, biodata PDF download, professional biodata, biodata sample, biodata sample pdf, create biodata for marriage online, how to create biodata, biodata format for job, biodata maker, biodata maker for job, biodata maker free, online biodata generator, resume builder, CV maker, job biodata, marriage biodata, free biodata templates" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="BiodataMakers" />
        <link rel="canonical" href="https://www.biodatamakers.com" />
        <title>BiodataMakers - Create Your Professional Biodata Online</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/biodata-make" element={<MakeBioData />} />
          <Route path="/template" element={<Template />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/term" element={<Term />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="*" element={<ErroPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
