import React, { useEffect, useRef, useState } from "react";
import "./form.css";
import images from "../../../assets/images/Group1000002583.png";
import deleteIcon from "../../../assets/images/Vector (3).png";
import detailimage from "../../../assets/images/Group 1000002590.png";
import familyImage from "../../../assets/images/Family.png";
import ContactImage from "../../../assets/images/Contact.png";
import OtherImage from "../../../assets/images/other.png";
import EditIcon from "@mui/icons-material/Edit";
import { Checkbox, Input } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";

export default function Form() {
  const storedFamilyData = JSON.parse(localStorage.getItem('familyData')) || [];
  const storedContactData = JSON.parse(localStorage.getItem('contactData')) || [];
  const storedOtherData = JSON.parse(localStorage.getItem('otherData')) || [];
  const PersonalData = JSON.parse(localStorage.getItem('personalData')) || [];


  const storedPersonalHeader = localStorage.getItem('personalDetail') || "Personal Detail";
  const storedContactHeader = localStorage.getItem('contactDetail') || "Contact Detail";
  const storedFamilyHeader = localStorage.getItem('familyDetail') || "Family Detail";
  const storedCustomHeader = localStorage.getItem('otherDetail') || "Other Detail";
  const naviagte = useNavigate();

  const [personalfield, setFields] = useState(
    PersonalData.length > 0
      ? PersonalData
      :
      [
        { label: "Full Name", value: "", placeholder: "Enter Full Name", checked: false },
        { label: "Cast", value: "", placeholder: "Enter Cast", checked: false },
        { label: "Date Of Birth", value: "", placeholder: "Enter Date Of Birth", checked: false },
        { label: "Occupation", value: "", placeholder: "Enter Occupation", checked: false },
        { label: "Height", value: "", placeholder: "Enter Height", checked: false },
        { label: "Weight", value: "", placeholder: "Enter Weight", checked: false },
        { label: "Education", value: "", placeholder: "Enter Education", checked: false },
      ]);

  const [familyFields, setFamilyFields] = useState(
    storedFamilyData.length > 0
      ? storedFamilyData
      : [
        { label: "Father's Name", placeholder: "Enter Father's Name", value: "", checked: false },
        { label: "Father's Occupation", placeholder: "Enter Father's Occupation", value: "", checked: false },
        { label: "Mother's Name", placeholder: "Enter Mother's Name", value: "", checked: false },
        { label: "Mother's Occupation", placeholder: "Enter Mother's Occupation", value: "", checked: false },
        { label: "Brother's Name", placeholder: "Enter Brother's Name", value: "", checked: false },
        { label: "Sister's Name", placeholder: "Enter Sister's Name", value: "", checked: false },
        { label: "Maternal", placeholder: "Enter Maternal", value: "", checked: false },
      ]
  );

  const [contactFields, setContactFields] = useState(
    storedContactData.length > 0
      ? storedContactData
      : [
        { label: "Contact Person", placeholder: "Enter Contact Person", value: "", checked: false },
        { label: "Contact Number", placeholder: "Enter Contact Number", value: "", checked: false },
        { label: "Address", placeholder: "Enter Address", value: "", checked: false },
      ]
  );

  const [customFields, setCustomFields] = useState(
    storedOtherData.length > 0
      ? storedOtherData
      : [{ label: "Custom Field", placeholder: "Enter value", value: "", checked: false }]
  );

  const [isEditingHeader, setIsEditingHeader] = useState(false);
  const [isEditingHeadercontact, setIsEditingHeadercontact] = useState(false);
  const [isEditingHeaderfamily, setIsEditingHeaderfamily] = useState(false);
  const [isEditingHeadercustom, setIsEditingHeadercustom] = useState(false);


  const [personalHeader, setPersonalHeader] = useState(storedPersonalHeader);
  const [contactHeader, setContactHeader] = useState(storedContactHeader);
  const [familyHeader, setFamilyHeader] = useState(storedFamilyHeader);
  const [customHeader, setCustomHeader] = useState(storedCustomHeader);

  const [isFormCollapsed, setIsFormCollapsed] = useState(false);
  const [iscontactHeader, setcontactHeader] = useState(false);
  const [isfamilyHeader, setfamilyHeader] = useState(false);
  const [iscustomHeader, setIscustomHeader] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDeleteClick = () => {
    setSelectedImage()
  }
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Image = reader.result;
        setSelectedImage(base64Image);
        localStorage.setItem('selectedImage', base64Image);
        console.log("Base64 Image:", base64Image);
      };

      reader.readAsDataURL(selectedFile);
    }
  };


  const fieldvalue = personalfield.filter((i) => i.value !== "" && i.checked)
  const familyFieldsvalue = familyFields.filter((i) => i.value !== "" && i.checked)
  const contactFieldsvalue = contactFields.filter((i) => i.value !== "" && i.checked)
  const customFieldsvalue = customFields.filter((i) => i.value !== "" && i.checked)


  useEffect(() => {
    localStorage.setItem('personalData', JSON.stringify(fieldvalue));
    localStorage.setItem('familyData', JSON.stringify(familyFieldsvalue));
    localStorage.setItem('contactData', JSON.stringify(contactFieldsvalue));
    localStorage.setItem('otherData', JSON.stringify(customFieldsvalue));
    localStorage.setItem('personalDetail', personalHeader);
    localStorage.setItem('contactDetail', contactHeader);
    localStorage.setItem('familyDetail', familyHeader);
    localStorage.setItem('otherDetail', customHeader);
  }, [personalfield, familyFields, contactFields, customFields, personalHeader, contactHeader, familyHeader, customHeader]);

  const handleAddField = () => {
    setFields([...personalfield, { label: "New Field", value: "Enter Value", isNew: true, isEditing: true, checked: true }]);
  };

  const handleDeleteField = (index, setState, currentState) => {
    const newFields = currentState.filter((_, i) => i !== index);
    setState(newFields);
  };

  const handleHeaderChange = (e, setHeader) => {
    setHeader(e.target.value);
  };

  const handleHeaderDoubleClick = () => {
    console.log("object");
    setIsEditingHeader(true);
  };

  const handleHeaderBlur = () => {
    setIsEditingHeader(false);
  };
  const handleHeaderfamily = () => {
    console.log("object");
    setIsEditingHeaderfamily(true);
  };

  const handleHeaderBlurfamily = () => {
    setIsEditingHeaderfamily(false);
  };
  const handleHeadercontact = () => {
    console.log("object");
    setIsEditingHeadercontact(true);
  };

  const handleHeaderBlurcontact = () => {
    setIsEditingHeadercontact(false);
  };
  const handleHeadercustom = () => {
    console.log("object");
    setIsEditingHeadercustom(true);
  };

  const handleHeaderBlurcustom = () => {
    setIsEditingHeadercustom(false);
  };
  const toggleFormCollapse = () => {
    setIsFormCollapsed(!isFormCollapsed);
  };
  const togglecontact = () => {
    setcontactHeader(!iscontactHeader);
  };
  const togglefamily = () => {
    setfamilyHeader(!isfamilyHeader);
  };
  const togglecustom = () => {
    setIscustomHeader(!iscustomHeader);
  };

  const handleLabelChange = (e, index, setState, currentState) => {
    const newFields = [...currentState];
    newFields[index].label = e.target.value;
    setState(newFields);
  };

  const handleLabelBlur = (index, setState, currentState) => {
    const newFields = [...currentState];
    newFields[index].isNew = false;
    newFields[index].isEditing = false;
    setState(newFields);
  };

  const handleEditClick = (index, setState, currentState) => {
    const newFields = [...currentState];
    newFields[index].isEditing = true;
    setState(newFields);
  };

  const handleCheckboxChange = (index, setState, currentState) => {
    const newFields = [...currentState];
    newFields[index].checked = !newFields[index].checked;
    setState(newFields);
  };

  const handleSave = () => {

    naviagte("/templates");
  };

  const renderFields = (fields, setState) => (
    console.log(fields, "fieldsfieldsfields"),
    fields.map((field, index) => (
      <div className="form-field" key={index}>
        <div className="field-label">
          <div className="label-icon">
            <Checkbox
              color="success"
              size="lg"
              variant="solid"
              checked={field.checked}
              onChange={() => handleCheckboxChange(index, setState, fields)}
            />
          </div>
          {field.isEditing ? (
            <input
              type="text"
              value={field.label}
              onChange={(e) => handleLabelChange(e, index, setState, fields)}
              onBlur={() => handleLabelBlur(index, setState, fields)}
              className="label-input"
              autoFocus
            />
          ) : (
            <div className="label-text" style={{ display: "flex" }}>
              {field.label}
              <EditIcon
                onClick={() => handleEditClick(index, setState, fields)}
                className="edit-icon"
                style={{ cursor: 'pointer', marginLeft: '10px', width: "15px" }}
              />
            </div>
          )}
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder={field.placeholder}
            value={field.value}
            className={`field-input ${field.value === "Enter Value" ? "empty" : "filled"}`}
            onChange={(e) => {
              const newFields = [...fields];
              newFields[index].value = e.target.value;
              newFields[index].checked = true;
              setState(newFields);
            }}
          />
          <div className="delete-icon" onClick={() => handleDeleteField(index, setState, fields)}>
            <img src={deleteIcon} alt="delete icon" />
          </div>
        </div>
      </div>
    ))
  );

  return (
    <div>
      <Header />

      <div className='from-main'>
        <div>
          <div className='form-headers'>
            CREATE BIO-MAKER
          </div>
          <p className='form-text'>Simple Steps to Make Your Marriage Bio-Data</p>
        </div>
        <div className="imagebackground">
          {selectedImage ?
            <div >
              <div onClick={handleDeleteClick} style={{ position: "absolute" }}>
                <img src={deleteIcon} alt="Edit" style={{ width: "20px", position: "relative", left: "165px", cursor: "pointer" }} />
              </div>
              <div className="image-container">
                <img
                  src={selectedImage}
                  alt="Selected"
                  onClick={handleButtonClick}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
            :
            <div className="image-setup" onClick={handleButtonClick}>
              <div className="icon-background">
                <img src={images} alt="icon" />
              </div>
              <input
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none", height: "250px", width: "200px" }}
                type="file"
              />
              <div className="image-detail">
                <p style={{ margin: "0px" }}>Select Image</p>
                <p style={{ margin: "5px" }}>(Max size 2MB)</p>
              </div>
            </div>
          }
        </div>
        <div className="form-container">
          <div className="form-header">
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", cursor: "pointer" }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
                <img src={detailimage} style={{ height: "40px" }} alt="edit icon" />
                {isEditingHeader ? (
                  <input
                    type="text"
                    value={personalHeader}
                    onChange={(e) => handleHeaderChange(e, setPersonalHeader)}
                    onBlur={handleHeaderBlur}
                    className="header-input"
                    autoFocus
                  />
                ) : (
                  <div style={{ display: "flex" }}>
                    <h2 className="editable-header">
                      {personalHeader}
                    </h2>
                    <EditIcon
                      onClick={handleHeaderDoubleClick}
                      className="edit-icon"
                      style={{ cursor: 'pointer', marginLeft: '10px', width: "20px" }}
                    />
                  </div>

                )}
              </div>
              <div className="toggle-collapse" onClick={toggleFormCollapse}>
                {isFormCollapsed ? '▼' : '▲'}
              </div>
            </div>
          </div>
          {!isFormCollapsed && (
            <>
              <div className="fields-container">
                {renderFields(personalfield, setFields)}
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                  <button className="add-new-button" onClick={handleAddField}>
                    + Add Field
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="form-container">
          <div className="form-header">
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", cursor: "pointer" }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
                <img src={familyImage} style={{ height: "40px" }} alt="edit icon" />
                {isEditingHeaderfamily ? (
                  <input
                    type="text"
                    value={familyHeader}
                    onChange={(e) => handleHeaderChange(e, setFamilyHeader)}
                    onBlur={handleHeaderBlurfamily}
                    className="header-input"
                    autoFocus
                  />
                ) : (
                  <div style={{ display: "flex" }}>
                    <h2 onDoubleClick={handleHeaderfamily} className="editable-header">
                      {familyHeader}
                    </h2>
                    <EditIcon
                      onClick={handleHeaderfamily}
                      className="edit-icon"
                      style={{ cursor: 'pointer', marginLeft: '10px', width: "20px" }}
                    />
                  </div>
                )}
              </div>
              <div className="toggle-collapse" onClick={togglefamily}>
                {isfamilyHeader ? '▼' : '▲'}
              </div>
            </div>
          </div>
          {!isfamilyHeader && (
            <>
              <div className="fields-container">
                {renderFields(familyFields, setFamilyFields)}

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                  <button className="add-new-button" onClick={() => setFamilyFields([...familyFields, { label: "New Family Field", placeholder: "Enter Value", value: "", isNew: true, isEditing: true, checked: true }])}>
                    + Add Field
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="form-container">
          <div className="form-header">
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", cursor: "pointer" }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
                <img src={ContactImage} style={{ height: "40px" }} alt="edit icon" />
                {isEditingHeadercontact ? (
                  <input
                    type="text"
                    value={contactHeader}
                    onChange={(e) => handleHeaderChange(e, setContactHeader)}
                    onBlur={handleHeaderBlurcontact}
                    className="header-input"
                    autoFocus
                  />
                ) : (
                  <div style={{ display: "flex" }}>
                    <h2 onDoubleClick={handleHeadercontact} className="editable-header">
                      {contactHeader}
                    </h2>
                    <EditIcon
                      onClick={handleHeadercontact}
                      className="edit-icon"
                      style={{ cursor: 'pointer', marginLeft: '10px', width: "20px" }}
                    />
                  </div>
                )}
              </div>
              <div className="toggle-collapse" onClick={togglecontact}>
                {iscontactHeader ? '▼' : '▲'}
              </div>
            </div>
          </div>
          {!iscontactHeader && (
            <>
              <div className="fields-container">
                {renderFields(contactFields, setContactFields)}

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                  <button className="add-new-button" onClick={() => setContactFields([...contactFields, { label: "New Contact Field", placeholder: "Enter Value", value: "", isNew: true, isEditing: true, checked: true }])}>
                    + Add Field
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="form-container">
          <div className="form-header">
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", cursor: "pointer" }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
                <img src={OtherImage} style={{ height: "40px" }} alt="edit icon" />
                {isEditingHeadercustom ? (
                  <input
                    type="text"
                    value={customHeader}
                    onChange={(e) => handleHeaderChange(e, setCustomHeader)}
                    onBlur={handleHeaderBlurcustom}
                    className="header-input"
                    autoFocus
                  />
                ) : (
                  <div style={{ display: "flex" }}>
                    <h2 onDoubleClick={handleHeadercustom} className="editable-header">
                      {customHeader}
                    </h2>
                    <EditIcon
                      onClick={handleHeadercustom}
                      className="edit-icon"
                      style={{ cursor: 'pointer', marginLeft: '10px', width: "20px" }}
                    />
                  </div>
                )}
              </div>
              <div className="toggle-collapse" onClick={togglecustom}>
                {iscustomHeader ? '▼' : '▲'}
              </div>
            </div>
          </div>
          {!iscustomHeader && (
            <>
              <div className="fields-container">
                {renderFields(customFields, setCustomFields)}

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                  <button className="add-new-button" onClick={() => setCustomFields([...customFields, { label: "New Custom Field", placeholder: "Enter Value", value: "", isNew: true, isEditing: true, checked: true }])}>
                    + Add Field
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="form-footer">
          <button
            onClick={handleSave}
            className="cta hero_button"
          >
            Select Templates
            <span className="icon">
              <i className="fa-solid fa-chevron-right first"></i>
              <i className="fa-solid fa-chevron-right last"></i>
            </span>
          </button>
        </div>

      </div>
    </div>
  );
}
