import React from "react";
import { Helmet } from "react-helmet";
import Form from "./Form";
import Header from "../../components/Header";

const MakeBioData = () => {
  return (
    <>
      <Helmet>
        <title>Create Your Marriage Biodata | BiodataMakers</title>
        <meta
          name="description"
          content="Create your professional marriage biodata easily with BiodataMakers. Choose from a variety of templates, customize your biodata, and download in PDF format."
        />
        <meta
          name="keywords"
          content="create biodata, marriage biodata maker, biodata templates, customizable biodata, biodata PDF, online biodata maker"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="BiodataMakers" />
        <link rel="canonical" href="https://www.biodatamakers.com/biodata-make" />
        <meta property="og:title" content="Create Your Marriage Biodata | BiodataMakers" />
        <meta
          property="og:description"
          content="Create your professional marriage biodata easily with BiodataMakers. Choose from a variety of templates, customize your biodata, and download in PDF format."
        />
        <meta property="og:url" content="https://www.biodatamakers.com/biodata-make" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.biodatamakers.com/assets/images/biodata_creation.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Create Your Marriage Biodata | BiodataMakers" />
        <meta
          name="twitter:description"
          content="Create your professional marriage biodata easily with BiodataMakers. Choose from a variety of templates, customize your biodata, and download in PDF format."
        />
        <meta
          name="twitter:image"
          content="https://www.biodatamakers.com/assets/images/biodata_creation.png"
        />
      </Helmet>
      <Header />
      <Form />
    </>
  );
};

export default MakeBioData;
