import React, { useState } from "react";
import "./styles.css";
import sample1 from "../../../assets/sampleimage/Screenshot 2023-12-09 103109.png";
import sample2 from "../../../assets/sampleimage/1.png";
import sample3 from "../../../assets/sampleimage/Screenshot 2023-12-09 103222.png";
import template281 from "../../../assets/newdesign/281.jpg";
import { Modal } from "@mui/material";

const ModalComponent = ({ opens, handleClose }) => {
  const [selectedImage, setSelectedImage] = useState(template281);
  const savedImage = localStorage.getItem('selectedImage');

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  return (
    <>
      <Modal
        open={opens}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`custom-modal ${opens ? "fade-in" : "fade-out"}`}
      >
        <div className="modal-overlay">
          <div className="modal-container">
            <div className="modal-content">
              <h1>Download Template</h1>
              <p>Download & Share Your Bio Data Template</p>
              <div className="biodata-preview">
                <img src={savedImage} alt="Bio Data Template" />
              </div>
              <div className="template-options">
                <img
                  src={sample1}
                  alt="Template 1"
                  onClick={() => handleImageClick(sample1)}
                />
                <img
                  src={sample2}
                  alt="Template 2"
                  onClick={() => handleImageClick(sample2)}
                />
                <img
                  src={sample3}
                  alt="Template 3"
                  onClick={() => handleImageClick(sample3)}
                />
                {/* <img
                  src={biodataImage4}
                  alt="Template 4"
                  onClick={() => handleImageClick(biodataImage4)}
                /> */}
              </div>
              <div className="button-container">
                <button className="cancel-button" onClick={handleClose}>
                  Cancel X
                </button>
                <button className="download-button">Download</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalComponent;
