import React from "react";
import ErrorImage from "../../assets/images/error.png";
import { useNavigate } from "react-router-dom";

const ErroPage = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: `${window.innerHeight - 30}px`,
        width: `${window.innerWidth - 30}px`,
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={ErrorImage}
        alt="not found"
        style={{ height: "400px", width: "400px" }}
      />
      <div>
        <button
          className="add-new-button"
          onClick={() => navigate("/")}
        >
          Back to home
        </button>
      </div>
    </div>
  );
};

export default ErroPage;
