import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../assets/images/logo.png"
const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <header
        id="header"
        className="fixed-top d-flex align-items-center header-transparent"
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div id="logo">
            <a href="/">
              <img style={{ width: "100%" }} src={logo} alt="Logo" />
            </a>
          </div>

          <nav id="navbar" className="navbar">
            <ul className={`nav-links ${isDrawerOpen ? "open" : ""}`}>
              <li>
                <Link className="nav-link scrollto" to="/" onClick={toggleDrawer}>
                  Home
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="/template" onClick={toggleDrawer}>
                  Template
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="/blog" onClick={toggleDrawer}>
                  Blog
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="/term" onClick={toggleDrawer}>
                  Term
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="/policy" onClick={toggleDrawer}>
                  Policy
                </Link>
              </li>
              {/* <button className="nav-link scrollto login_btn">LOGIN</button> */}
            </ul>
            <div className="mobile-nav-toggle" onClick={toggleDrawer}>
              {isDrawerOpen ? <FaTimes /> : <FaBars />}
            </div>
          </nav>
        </div>
      </header>

      <style jsx>{`
        .nav-links {
          list-style: none;
          display: flex;
          flex-direction: row;
          padding-left: 0;
        }

        .nav-links li {
          margin: 0 10px;
        }

        .mobile-nav-toggle {
          display: none;
          font-size: 24px;
          cursor: pointer;
        }

        @media (max-width: 768px) {
          .nav-links {
            flex-direction: column;
            position: fixed;
            top: 0;
            left: 0;
            width: 250px;
            height: 100%;
            background-color: #fff;
            transform: translateX(-100%);
            transition: transform 0.3s ease;
          }

          .nav-links.open {
            transform: translateX(0);
          }

          .nav-links li {
            margin: 20px 0;
          }

          .mobile-nav-toggle {
            display: block;
          }

          .navbar {
            position: relative;
          }
        }
      `}</style>
    </>
  );
};

export default Header;
