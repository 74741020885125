import React from "react";
import "./index.css";
import App from "./App";
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");

const renderApp = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Check if the root element already has child nodes
if (rootElement.hasChildNodes()) {
  // If it does, hydrate the app
  hydrate(renderApp(), rootElement);
} else {
  // If not, render the app
  render(renderApp(), rootElement);
}
