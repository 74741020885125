import React, { useState, useEffect } from "react";
import template1 from "../../assets/templates/1.jpeg";
import template2 from "../../assets/templates/99.jpeg";
import template3 from "../../assets/templates/100.jpeg";
import template4 from "../../assets/templates/101.jpeg";
import template5 from "../../assets/templates/5.jpeg";
import template10 from "../../assets/templates/10.jpeg";
import template12 from "../../assets/templates/12.jpeg";
import template13 from "../../assets/templates/13.jpeg";
import template14 from "../../assets/templates/14.jpeg";
import template16 from "../../assets/templates/16.jpeg";
import template19 from "../../assets/templates/19.jpeg";
import template25 from "../../assets/templates/25.jpeg";
import template26 from "../../assets/templates/26.jpeg";
import template30 from "../../assets/templates/30.jpeg";
import template31 from "../../assets/templates/31.jpeg";
import template32 from "../../assets/templates/32.jpeg";
import template33 from "../../assets/templates/33.jpeg";
import template35 from "../../assets/templates/35.jpeg";
import template45 from "../../assets/templates/45.webp";
import template46 from "../../assets/templates/46.jpg";
import template48 from "../../assets/templates/48.webp";
import template49 from "../../assets/templates/49.jpg";
import template51 from "../../assets/templates/51.webp";
import template52 from "../../assets/templates/52.webp";
import template101 from "../../assets/newdesign/101.jpg";
import template102 from "../../assets/newdesign/102.jpg";
import template103 from "../../assets/newdesign/103.jpg";
import template104 from "../../assets/newdesign/104.jpg";
import template105 from "../../assets/newdesign/105.jpg";
import template106 from "../../assets/newdesign/106.jpg";
import template107 from "../../assets/newdesign/107.jpg";
import template108 from "../../assets/newdesign/108.jpg";
import template110 from "../../assets/newdesign/110.jpg";
import template111 from "../../assets/newdesign/111.jpg";
import template112 from "../../assets/newdesign/112.jpg";
import template113 from "../../assets/newdesign/113.jpg";
import template114 from "../../assets/newdesign/114.jpg";
import template115 from "../../assets/newdesign/115.jpg";
import template116 from "../../assets/newdesign/116.jpg";
import template117 from "../../assets/newdesign/117.jpg";
import template118 from "../../assets/newdesign/118.jpg";
import template119 from "../../assets/newdesign/119.jpg";
import template121 from "../../assets/newdesign/121.jpg";
import template122 from "../../assets/newdesign/122.jpg";
import template123 from "../../assets/newdesign/123.jpg";
import template124 from "../../assets/newdesign/124.jpg";
import template125 from "../../assets/newdesign/125.jpg";
import template126 from "../../assets/newdesign/126.jpg";
import template127 from "../../assets/newdesign/127.jpg";
import template128 from "../../assets/newdesign/128.jpg";
import template129 from "../../assets/newdesign/129.jpg";
import template130 from "../../assets/newdesign/130.jpg";
import template131 from "../../assets/newdesign/131.jpg";
import template132 from "../../assets/newdesign/132.jpg";
import template133 from "../../assets/newdesign/133.jpg";
import template134 from "../../assets/newdesign/134.jpg";
import template135 from "../../assets/newdesign/135.jpg";
import template136 from "../../assets/newdesign/136.jpg";
import template137 from "../../assets/newdesign/137.jpg";
import template138 from "../../assets/newdesign/138.jpg";
import template139 from "../../assets/newdesign/139.jpg";
import template140 from "../../assets/newdesign/140.jpg";
import template141 from "../../assets/newdesign/141.jpg";
import template201 from "../../assets/newdesign/201.avif";
import template202 from "../../assets/newdesign/202.avif";
import template203 from "../../assets/newdesign/203.avif";
import template204 from "../../assets/newdesign/204.avif";
import template205 from "../../assets/newdesign/205.avif";
import template206 from "../../assets/newdesign/206.jpg";
import template207 from "../../assets/newdesign/207.jpg";
import template208 from "../../assets/newdesign/208.jpg";
import template210 from "../../assets/newdesign/210.jpg";
import template211 from "../../assets/newdesign/211.jpg";
import template212 from "../../assets/newdesign/212.jpg";
import template213 from "../../assets/newdesign/213.jpg";
import template216 from "../../assets/newdesign/216.jpg";
import template217 from "../../assets/newdesign/217.jpg";
import template218 from "../../assets/newdesign/218.jpg";
import template219 from "../../assets/newdesign/219.jpg";
import template220 from "../../assets/newdesign/220.jpg";
import template221 from "../../assets/newdesign/221.jpg";
import template222 from "../../assets/newdesign/222.jpg";
import template223 from "../../assets/newdesign/223.jpg";
import template224 from "../../assets/newdesign/224.jpg";
import template225 from "../../assets/newdesign/225.jpg";
import template226 from "../../assets/newdesign/226.jpg";
import template228 from "../../assets/newdesign/228.jpg";
import template229 from "../../assets/newdesign/229.jpg";
import template231 from "../../assets/newdesign/231.jpg";
import template232 from "../../assets/newdesign/232.jpg";
import template233 from "../../assets/newdesign/233.jpg";
import template234 from "../../assets/newdesign/234.jpg";
import template235 from "../../assets/newdesign/235.jpg";
import template236 from "../../assets/newdesign/236.jpg";
import template237 from "../../assets/newdesign/237.jpg";
import template238 from "../../assets/newdesign/238.jpg";
import template239 from "../../assets/newdesign/239.jpg";
import template240 from "../../assets/newdesign/240.jpg";
import template241 from "../../assets/newdesign/241.jpg";
import template242 from "../../assets/newdesign/242.jpg";
import template243 from "../../assets/newdesign/243.jpg";
import template244 from "../../assets/newdesign/244.jpg";
import template245 from "../../assets/newdesign/245.jpg";
import template246 from "../../assets/newdesign/246.jpg";
import template247 from "../../assets/newdesign/247.jpg";
import template248 from "../../assets/newdesign/248.jpg";
import template249 from "../../assets/newdesign/249.jpg";
import template250 from "../../assets/newdesign/250.jpg";
import template251 from "../../assets/newdesign/251.jpg";
import template252 from "../../assets/newdesign/252.jpg";
import template253 from "../../assets/newdesign/253.jpg";
import template254 from "../../assets/newdesign/254.jpg";
import template255 from "../../assets/newdesign/255.jpg";
import template256 from "../../assets/newdesign/256.jpg";
import template257 from "../../assets/newdesign/257.jpg";
import template258 from "../../assets/newdesign/258.jpg";
import template259 from "../../assets/newdesign/259.jpg";
import template260 from "../../assets/newdesign/260.jpg";
import template261 from "../../assets/newdesign/261.jpg";
import template262 from "../../assets/newdesign/262.jpg";
import template263 from "../../assets/newdesign/263.jpg";
import template264 from "../../assets/newdesign/264.jpg";
import template265 from "../../assets/newdesign/265.jpg";
import template266 from "../../assets/newdesign/266.jpg";
import template267 from "../../assets/newdesign/267.jpg";
import template268 from "../../assets/newdesign/268.jpg";
import template269 from "../../assets/newdesign/269.jpg";
import template270 from "../../assets/newdesign/270.jpg";
import template271 from "../../assets/newdesign/271.jpg";
import template272 from "../../assets/newdesign/272.jpg";
import template273 from "../../assets/newdesign/273.jpg";
import template274 from "../../assets/newdesign/274.jpg";
import template275 from "../../assets/newdesign/275.jpg";
import template276 from "../../assets/newdesign/276.jpg";
import template277 from "../../assets/newdesign/277.jpg";
import template278 from "../../assets/newdesign/278.jpg";
import template279 from "../../assets/newdesign/279.jpg";
import template280 from "../../assets/newdesign/280.jpg";
import template281 from "../../assets/newdesign/281.jpg";
import sample1 from "../../assets/sampleimage/Screenshot 2023-12-09 103109.png";
import sample2 from "../../assets/sampleimage/1.png";
import sample3 from "../../assets/sampleimage/Screenshot 2023-12-09 103222.png";
import jsPDF from "jspdf";
import { Link, useNavigate } from "react-router-dom";
import "./Templet.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import logo from "./image/8.jpg"
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import ModalComponent from "./Modal/modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "30px",
};
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "30px",
};

export default function Index() {
  let alldata = [
    template1,
    template5,
    template10,
    template12,
    template13,
    template14,
    template16,
    template19,
    template25,
    template26,
    template30,
    template31,
    template32,
    // template33,
    template35,
    template45,
    template46,
    template48,
    template49,
    template51,
    template52,
    template2,
    template3,
    template4,
    template101,
    template102,
    template103,
    template104,
    template105,
    template106,
    template107,
    template108,
    template110,
    template111,
    template112,
    template113,
    template114,
    template115,
    template116,
    template117,
    template118,
    template119,
    template121,
    template122,
    template123,
    template124,
    template125,
    template126,
    template127,
    template128,
    template129,
    template130,
    template131,
    template132,
    template133,
    template134,
    template135,
    template136,
    template137,
    template138,
    template139,
    template140,
    template141,
    template201,
    template202,
    template203,
    template204,
    template205,
    template206,
    template207,
    template208,
    template210,
    template211,
    template212,
    template213,
    template216,
    template217,
    template218,
    template219,
    template220,
    template221,
    template222,
    template223,
    template224,
    template225,
    template226,
    template228,
    template229,
    template231,
    template232,
    template233,
    template234,
    template235,
    template236,
    template237,
    template238,
    template239,
    template240,
    template241,
    template242,
    template243,
    template244,
    template245,
    template246,
    template247,
    template248,
    template249,
    template250,
    template251,
    template252,
    template253,
    template254,
    template255,
    template256,
    template257,
    template258,
    template259,
    template260,
    template261,
    template262,
    template263,
    template264,
    template265,
    template266,
    template267,
    template268,
    template269,
    template270,
    template271,
    template272,
    template273,
    template274,
    template275,
    template276,
    template277,
    template278,
    template279,
    template280,
    template281,
  ];

  const [selectedImage, setSelectedImage] = useState(null);
  const [Image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);

  const [previewImage, setPreviewImage] = useState(alldata[0]);
  const handleClose = () => setOpen(false);


  // State variables to store data retrieved from localStorage
  const [personalData, setPersonalData] = useState([]);
  const [familyData, setFamilyData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [otherDetail, setOtherDetail] = useState("");
  const [contactDetail, setContactDetail] = useState("");
  const [familyDetail, setFamilyDetail] = useState("");
  const [personalDetail, setPersonalDetail] = useState("");
  const [selectedPage, setSelectedPage] = useState('page2');

  const handlePageChange = (event) => {
    setSelectedPage(event.target.value);
    console.log(selectedPage);
  };
  // useEffect to run once when the component mounts


  useEffect(() => {
    console.log("Fetching data from localStorage...");

    // Retrieve data from localStorage
    const storedPersonalData = JSON.parse(localStorage.getItem('personalData')) || [];
    const storedFamilyData = JSON.parse(localStorage.getItem('familyData')) || [];
    const storedContactData = JSON.parse(localStorage.getItem('contactData')) || [];
    const storedOtherData = JSON.parse(localStorage.getItem('otherData')) || [];
    const storedOtherDetail = localStorage.getItem('otherDetail') || "";
    const storedContactDetail = localStorage.getItem('contactDetail') || "";
    const storedFamilyDetail = localStorage.getItem('familyDetail') || "";
    const storedPersonalDetail = localStorage.getItem('personalDetail') || "";

    console.log("Personal Data:", storedPersonalData);
    console.log("Family Data:", storedFamilyData);
    console.log("Contact Data:", storedContactData);
    console.log("Other Data:", storedOtherData);
    console.log("Other Detail:", storedOtherDetail);
    console.log("Contact Detail:", storedContactDetail);
    console.log("Family Detail:", storedFamilyDetail);
    console.log("Personal Detail:", storedPersonalDetail);

    // Update state variables with retrieved data
    setPersonalData(storedPersonalData);
    setFamilyData(storedFamilyData);
    setContactData(storedContactData);
    setOtherData(storedOtherData);
    setOtherDetail(storedOtherDetail);
    setContactDetail(storedContactDetail);
    setFamilyDetail(storedFamilyDetail);
    setPersonalDetail(storedPersonalDetail);

    console.log("Data fetching completed.");
  }, []);



  const handleGenerateButtonClick = async () => {
    setSelectedImage(previewImage);
    setOpen(true)
    const imageURL = previewImage

    const hiddenDiv = document.createElement('div');
    hiddenDiv.style.display = 'none';
    document.body.appendChild(hiddenDiv);

    const imgElement = document.createElement('img');

    imgElement.onload = () => {
      const aspectRatio = imgElement.width / imgElement.height;

      // Set canvas dimensions for full HD
      const canvasWidth = 1920;
      const canvasHeight = canvasWidth / aspectRatio;

      const canvas = document.createElement('canvas');
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const context = canvas.getContext('2d');
      context.drawImage(imgElement, 0, 0, canvasWidth, canvasHeight);

      // Convert the canvas to a Data URL
      const dataUrl = canvas.toDataURL('image/jpeg');

      // Create jsPDF instance
      const pdf = new jsPDF({
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
      });

      // Add the image to the PDF
      pdf.addImage(dataUrl, 'JPEG', 0, 0, 210, 297); // A4 dimensions




      // Add "BIO DATA" heading
      pdf.setFontSize(20);
      pdf.setFont('helvetica', 'bold'); // Set font to bold
      pdf.text('BIO DATA', 105, 30, { align: 'center' });
      let yPosition = 60; // Initial Y position for the details

      if (personalDetail.length > 0 && personalData.length > 0) {
        // Add personal details to the PDF
        pdf.setFontSize(17);
        pdf.setFont('helvetica', 'bold'); // Set font to bold
        pdf.text(personalDetail, 27, 50);
        pdf.setFont('helvetica', 'normal'); // Set font back to normal

        // Loop through personalData array and add details to the PDF
        pdf.setFontSize(12);
        personalData.forEach(({ label, value }) => {
          pdf.text(`${label}`, 27, yPosition);
          pdf.text(`:- ${value}`, 73, yPosition);
          yPosition += 9 // Increment Y position for the next detail
        });
      }

      // Add family details to the PDF with bold font and reduced top margin
      if (familyDetail.length > 0 && familyData.length > 0) {
        pdf.setFontSize(17);
        pdf.setFont('helvetica', 'bold'); // Set font to bold
        pdf.text(familyDetail, 27, yPosition + 3); // Adjusted top margin
        pdf.setFont('helvetica', 'normal'); // Set font back to normal

        // Loop through familyData array and add details to the PDF
        pdf.setFontSize(12);
        yPosition += 12; // Adjusted margin after familyDetail
        familyData.forEach(({ label, value }) => {
          pdf.text(`${label}`, 27, yPosition);
          pdf.text(`:- ${value}`, 73, yPosition);
          yPosition += 9 // Increment Y position for the next detail
        });
      }

      // Add contact details to the PDF with bold font and reduced top margin
      if (contactDetail.length > 0 && contactData.length > 0) {
        pdf.setFontSize(17);
        pdf.setFont('helvetica', 'bold'); // Set font to bold
        pdf.text(contactDetail, 27, yPosition + 3); // Adjusted top margin
        pdf.setFont('helvetica', 'normal'); // Set font back to normal

        // Loop through contactData array and add details to the PDF
        pdf.setFontSize(12);
        yPosition += 12; // Adjusted margin after contactDetail
        contactData.forEach(({ label, value }) => {
          pdf.text(`${label}`, 27, yPosition);
          pdf.text(`:- ${value}`, 73, yPosition);
          yPosition += 9 // Increment Y position for the next detail
        });
      }

      // Add other details to the PDF with bold font and reduced top margin
      if (otherData.length > 0 && otherDetail.length > 0) {
        pdf.setFontSize(17);
        pdf.setFont('helvetica', 'bold'); // Set font to bold
        pdf.text(otherDetail, 27, yPosition + 3); // Adjusted top margin
        pdf.setFont('helvetica', 'normal'); // Set font back to normal

        // Loop through otherData array and add details to the PDF
        pdf.setFontSize(12);
        yPosition += 12; // Adjusted margin after otherDetail
        otherData.forEach(({ label, value }) => {
          pdf.text(`${label}`, 27, yPosition);
          pdf.text(`:- ${value}`, 73, yPosition);
          yPosition += 9 // Increment Y position for the next detail
        });
      }

      const savedImage = localStorage.getItem('selectedImage');
      const savedotherImage = localStorage.getItem('otherselectedImage');


      if (selectedPage === "page1"
      ) {
        if (savedImage) {
          pdf.addPage();
          pdf.addImage(savedImage, 'JPEG', 0, 0, 210, 297);
        }

      }
      else {


        if (savedImage) {
          const imageWidth = 40; // Set the desired width
          const imageHeight = 50; // Set the desired height
          const xCoordinate = 150; // Set the X coordinate
          const yCoordinate = 55; // Set the Y coordinate
          const borderWidth = 0.5; // Set the desired border width

          // Add the image
          pdf.addImage(savedImage, 'JPEG', xCoordinate, yCoordinate, imageWidth, imageHeight);

          // Draw a rectangle around the image to create a border
          pdf.setDrawColor(0); // Set the border color to black (RGB: 0, 0, 0)
          pdf.setLineWidth(borderWidth); // Set the border width
          pdf.rect(xCoordinate, yCoordinate, imageWidth, imageHeight);
        }

      }

      if (savedotherImage) {
        pdf.addPage();
        pdf.addImage(savedotherImage, 'JPEG', 0, 0, 210, 297);
      }

      // Save the PDF
      pdf.save(`Biodatamakers.pdf`);

      // Remove the hidden div from the DOM
      document.body.removeChild(hiddenDiv);
    };

    imgElement.src = imageURL;
    imgElement.style.width = '100%';
    hiddenDiv.appendChild(imgElement);
  };


  const handleGenerateButtonClicks = async () => {
    const biodataId = Date.now();

    setSelectedImage(previewImage);
    setOpen(true)
    const imageURL = previewImage

    const hiddenDiv = document.createElement('div');
    hiddenDiv.style.display = 'none';
    document.body.appendChild(hiddenDiv);

    const imgElement = document.createElement('img');

    imgElement.onload = () => {
      const aspectRatio = imgElement.width / imgElement.height;

      // Set canvas dimensions for full HD
      const canvasWidth = 1920;
      const canvasHeight = canvasWidth / aspectRatio;

      const canvas = document.createElement('canvas');
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const context = canvas.getContext('2d');
      context.drawImage(imgElement, 0, 0, canvasWidth, canvasHeight);

      // Convert the canvas to a Data URL
      const dataUrl = canvas.toDataURL('image/jpeg');

      // Create jsPDF instance
      const pdf = new jsPDF({
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
      });

      // Add the image to the PDF
      pdf.addImage(dataUrl, 'JPEG', 0, 0, 210, 297); // A4 dimensions




      // Add "BIO DATA" heading
      pdf.setFontSize(20);
      pdf.setFont('helvetica', 'bold'); // Set font to bold
      pdf.text('BIO DATA', 105, 30, { align: 'center' });
      let yPosition = 77; // Initial Y position for the details

      // Add personal details to the PDF
      if (personalDetail.length > 0 && personalData.length > 0) {
        pdf.setFontSize(17);
        pdf.setFont('helvetica', 'bold'); // Set font to bold
        pdf.text(personalDetail, 27, 68);
        pdf.setFont('helvetica', 'normal'); // Set font back to normal

        // Loop through personalData array and add details to the PDF
        pdf.setFontSize(12);
        personalData.forEach(({ label, value }) => {
          pdf.text(`${label}`, 27, yPosition);
          pdf.text(`:- ${value}`, 73, yPosition);
          yPosition += 9 // Increment Y position for the next detail
        });
      }

      // Add family details to the PDF with bold font and reduced top margin
      if (familyDetail.length > 0 && familyData.length > 0) {
        pdf.setFontSize(17);
        pdf.setFont('helvetica', 'bold'); // Set font to bold
        pdf.text(familyDetail, 27, yPosition + 3); // Adjusted top margin
        pdf.setFont('helvetica', 'normal'); // Set font back to normal

        // Loop through familyData array and add details to the PDF
        pdf.setFontSize(12);
        yPosition += 12; // Adjusted margin after familyDetail
        familyData.forEach(({ label, value }) => {
          pdf.text(`${label}`, 27, yPosition);
          pdf.text(`:- ${value}`, 73, yPosition);
          yPosition += 9 // Increment Y position for the next detail
        });
      }

      // Add contact details to the PDF with bold font and reduced top margin
      if (contactDetail.length > 0 && contactData.length > 0) {
        pdf.setFontSize(17);
        pdf.setFont('helvetica', 'bold'); // Set font to bold
        pdf.text(contactDetail, 27, yPosition + 3); // Adjusted top margin
        pdf.setFont('helvetica', 'normal'); // Set font back to normal

        // Loop through contactData array and add details to the PDF
        pdf.setFontSize(12);
        yPosition += 12; // Adjusted margin after contactDetail
        contactData.forEach(({ label, value }) => {
          pdf.text(`${label}`, 27, yPosition);
          pdf.text(`:- ${value}`, 73, yPosition);
          yPosition += 9 // Increment Y position for the next detail
        });
      }

      // Add other details to the PDF with bold font and reduced top margin
      if (otherDetail.length > 0 && otherDetail.length > 0) {
        pdf.setFontSize(17);
        pdf.setFont('helvetica', 'bold'); // Set font to bold
        pdf.text(otherDetail, 27, yPosition + 3); // Adjusted top margin
        pdf.setFont('helvetica', 'normal'); // Set font back to normal

        // Loop through otherData array and add details to the PDF
        pdf.setFontSize(12);
        yPosition += 12; // Adjusted margin after otherDetail
        otherData.forEach(({ label, value }) => {
          pdf.text(`${label}`, 27, yPosition);
          pdf.text(`:- ${value}`, 73, yPosition);
          yPosition += 9 // Increment Y position for the next detail
        });
      }

      const savedImage = localStorage.getItem('selectedImage');

      if (savedImage) {
        const imageSize = 30;

        // Calculate the Y position for the circular image
        const imageYPosition = 35

        // Create a circular mask using clip
        pdf.saveGraphicsState();

        // Set the image as a circle using a clip
        pdf.circle(imageSize / 2 + 90, imageYPosition + imageSize / 2, imageSize / 2);

        // Clip to the circular path
        pdf.clip();

        // Create a circular mask for the image
        pdf.roundedRect(90, imageYPosition, imageSize, imageSize, imageSize / 2, imageSize / 2, 'S');

        // Clip to the rounded rectangular path
        pdf.clip();

        // Add the image to the PDF
        pdf.addImage(savedImage, 'JPEG', 90, imageYPosition, imageSize, imageSize);

        // Reset the clipping path
        pdf.restoreGraphicsState();
      }

      const savedotherImage = localStorage.getItem('otherselectedImage');
      if (savedotherImage) {
        pdf.addPage();
        pdf.addImage(savedotherImage, 'JPEG', 0, 0, 210, 297);
      }

      // Save the PDF
      pdf.save(`Biodatamakers.pdf`);

      // Remove the hidden div from the DOM
      document.body.removeChild(hiddenDiv);
    };

    imgElement.src = imageURL;
    imgElement.style.width = '100%';
    hiddenDiv.appendChild(imgElement);
  };
  const savedImage = localStorage.getItem('selectedImage');


  const showpreview = (index) => {
    setPreviewImage(alldata[index]);
    setOpens(true)
    setSelectedImage(index);

  }
  useEffect(() => {
    // Scroll to the top when the component mounts or when the page changes
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  // const onsave = () => {
  //     localStorage.setItem('selectedImage', "");
  //     localStorage.setItem('otherselectedImage', "");
  //     localStorage.setItem('personalDetail', "");
  //     localStorage.setItem('familyDetail', "");
  //     localStorage.setItem('contactDetail', "");
  //     localStorage.setItem('otherDetail', "");
  //     localStorage.setItem('otherData', JSON.stringify(""));
  //     localStorage.setItem('contactData', JSON.stringify(""));
  //     localStorage.setItem('familyData', JSON.stringify(""));
  //     localStorage.setItem('personalData', JSON.stringify(""));
  // }



  return (
    <div>
      <Helmet>
        <title>Crafting a Professional Marriage Biodata in 3 Easy Steps</title>
        <meta
          name="description"
          content="Learn how to create a professional marriage biodata in just 3 easy steps with our comprehensive guide. Discover the best online tools and tips for crafting a perfect PDF biodata."
        />
        <meta
          name="keywords"
          content="create biodata, marriage biodata maker, biodata templates, customize biodata, PDF biodata, online biodata maker"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.biodatamakers.com/blog" />
        <meta property="og:title" content="Crafting a Professional Marriage Biodata in 3 Easy Steps" />
        <meta
          property="og:description"
          content="Learn how to create a professional marriage biodata in just 3 easy steps with our comprehensive guide. Discover the best online tools and tips for crafting a perfect PDF biodata."
        />
        <meta property="og:url" content="https://www.biodatamakers.com/blog" />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content="https://www.biodatamakers.com/assets/images/biodata_blog.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Crafting a Professional Marriage Biodata in 3 Easy Steps" />
        <meta
          name="twitter:description"
          content="Learn how to create a professional marriage biodata in just 3 easy steps with our comprehensive guide. Discover the best online tools and tips for crafting a perfect PDF biodata."
        />
        <meta
          name="twitter:image"
          content="https://www.biodatamakers.com/assets/images/biodata_blog.png"
        />
      </Helmet>
      <Header />

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`custom-modal ${opens ? 'fade-in' : 'fade-out'}`}

        >
          <Box sx={styles}>
            <div>
              <div style={{
                display: "flex", justifyContent: "center", alignItems: "center"

              }}>
                <CheckCircleOutlineIcon style={{ fontSize: "80px", color: "#63ba8b", textAlign: "center" }} />
              </div>
              <div>
                <h3>Download Successful</h3>
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <Link to="/"><button style={{ backgroundColor: "white", fontSize: "15px", fontWeight: "bold", color: "black", borderRadius: "30px", height: "35px", width: "70px", border: "1px solid skyblue" }} >Home</button></Link>

                  <button onClick={() => {
                    setOpen(false);
                  }} style={{ backgroundColor: "white", fontSize: "15px", fontWeight: "bold", color: "black", borderRadius: "30px", height: "35px", width: "70px", border: "1px solid skyblue" }}>Back</button>

                </div>
              </Typography>
            </div>
          </Box>
        </Modal>

      </div>

      <Modal
        open={opens}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`custom-modal ${opens ? 'fade-in' : 'fade-out'}`}

      >
        {selectedPage === "page1" || selectedPage === "page2" ?
          <Box sx={style}
          >
            <div>
              <div>
                <div
                  style={{
                    // padding: "10px",
                    // height: '650px',
                    // width: '510px',
                    // marginTop: '80px',
                    // position: "fixed"
                  }}
                // className='boxshadow'

                >
                  <div
                    style={{
                      height: '425px',
                      width: '300px',
                      backgroundImage: `url(${previewImage})`,
                      backgroundPosition: 'center',
                      backgroundSize: "contain",
                      // position: "fixed",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "20px"
                    }}
                  // className='boxshadow'

                  >
                    <div>
                      <div
                        style={{ textAlign: "center", paddingTop: "40px", fontSize: "15px", fontWeight: "bold" }}
                      >BIO DATA</div>
                      {
                        personalDetail.length > 0 && personalData.length > 0 &&
                        <div>
                          <div
                            style={{ fontSize: "8px", fontWeight: "bold", marginLeft: "40px", marginTop: "10px" }}
                          >{personalDetail}</div>
                          {personalData.map((data) => {

                            return (
                              <div className='row' style={{ fontSize: "6px", display: "flex", margin: "3px", marginLeft: "30px" }} key={data.label}>
                                <div className='col-4'>{data.label}</div>    <div className='col-5'>:- {data.value}</div>
                              </div>
                            );
                          })}
                        </div>
                      }
                      {selectedPage === "page1" ?
                        "" : <div style={{ position: "fixed", top: "120px", right: "70px", }}><img style={{ height: "60px", width: "40px", objectFit: "cover" }} src={savedImage}></img></div>

                      }

                      {
                        familyDetail.length > 0 && familyData.length > 0 &&
                        <div>
                          <div
                            style={{ fontSize: "8px", fontWeight: "bold", marginLeft: "40px", marginTop: "5px" }}
                          >{familyDetail}</div>
                          {familyData.map((data) => {

                            return (
                              <div className='row' style={{ fontSize: "6px", display: "flex", margin: "3px", marginLeft: "30px" }} key={data.label}>
                                <div className='col-4'>{data.label}</div>    <div className='col-8'>:- {data.value}</div>
                              </div>
                            );
                          })}
                        </div>
                      }
                      {
                        contactData.length > 0 && contactDetail.length > 0 &&
                        <div>
                          <div
                            style={{ fontSize: "8px", fontWeight: "bold", marginLeft: "40px", marginTop: "5px" }}
                          >{contactDetail}</div>
                          {contactData.map((data) => {

                            return (
                              <div className='row' style={{ fontSize: "6px", display: "flex", margin: "3px", marginLeft: "30px" }} key={data.label}>
                                <div className='col-4'>{data.label}</div> <div className='col-8'>:- {data.value}</div>
                              </div>
                            );
                          })}
                        </div>
                      }
                      {
                        otherDetail.length > 0 && otherData.length > 0 &&
                        <div>
                          <div
                            style={{ fontSize: "8px", fontWeight: "bold", marginLeft: "40px", marginTop: "5px" }}
                          >{otherDetail}</div>
                          {otherData.map((data) => {
                            return (
                              <div className='row' style={{ fontSize: "6px", display: "flex", margin: "3px", marginLeft: "30px" }} key={data.label}>
                                <div className='col-4'>{data.label}</div>    <div className='col-8'>:- {data.value}</div>
                              </div>
                            );
                          })}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>

                  <form>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                        <img style={{ height: "120px", width: "80px", margin: "5px" }} src={sample1}></img>

                        <label
                          style={{ margin: "" }}
                        >
                          <input
                            type="checkbox"
                            value="page2"
                            checked={selectedPage === 'page2'}
                            onChange={handlePageChange}
                            style={{ margin: "" }}

                          />
                        </label>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", objectFit: "cover" }}>
                        <img style={{ height: "120px", width: "80px", margin: "5px" }} src={sample2}></img>

                        <label
                          style={{ margin: "" }}
                        >
                          <input
                            type="checkbox"
                            value="page1"
                            checked={selectedPage === 'page1'}
                            onChange={handlePageChange}
                            style={{ margin: "" }}

                          />
                        </label>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", objectFit: "cover" }}>
                        <img style={{ height: "120px", width: "80px", margin: "5px" }} src={sample3}></img>

                        <label
                          style={{ margin: "" }}
                        >
                          <input
                            type="checkbox"
                            value="page3"
                            checked={selectedPage === 'page3'}
                            onChange={handlePageChange}
                            style={{ margin: "" }}

                          />
                        </label>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <Link to=""><button onClick={() => selectedPage === "page1" || selectedPage === "page2" ? handleGenerateButtonClick() : handleGenerateButtonClicks()}
                    style={{ backgroundColor: "white", fontSize: "15px", fontWeight: "bold", color: "black", borderRadius: "30px", height: "35px", width: "90px", border: "1px solid #fc825b" }} s>Download</button></Link>
                  <Link to="/biodata-make"><button style={{ backgroundColor: "white", fontSize: "15px", fontWeight: "bold", color: "black", borderRadius: "30px", height: "35px", width: "70px", border: "1px solid #fc825b" }} >Edit</button></Link>

                  <button onClick={() => {
                    setOpens(false);
                  }} style={{ backgroundColor: "white", fontSize: "15px", fontWeight: "bold", color: "black", borderRadius: "30px", height: "35px", width: "70px", border: "1px solid #fc825b" }}>Cancel</button>
                </div>
              </Typography>
            </div>
          </Box> :
          <Box sx={style}>
            <div>
              <div>
                <div
                  style={{
                    // padding: "10px",
                    // height: '650px',
                    // width: '510px',
                    // marginTop: '80px',
                    // position: "fixed"
                  }}
                // className='boxshadow'

                >
                  <div
                    style={{
                      height: '430px',
                      width: '300px',
                      backgroundImage: `url(${previewImage})`,
                      backgroundPosition: 'center',
                      backgroundSize: "contain",
                      // position: "fixed",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "20px"
                    }}
                  >
                    <div>
                      <div
                        style={{ textAlign: "center", paddingTop: "40px", fontSize: "15px", fontWeight: "bold" }}
                      >BIO DATA</div>
                      <div>
                        <div
                          style={{ fontSize: "8px", fontWeight: "bold", marginLeft: "40px", marginTop: "40px" }}
                        >{personalDetail}</div>
                        {personalData.map((data, index) => {

                          return (
                            <div className='row' style={{ fontSize: "6px", display: "flex", margin: "3px", marginLeft: "30px" }} key={index}>
                              <div className='col-4'>{data.label}</div>    <div className='col-5'>:- {data.value}</div>
                            </div>
                          );
                        })}
                      </div>
                      <div style={{ position: "fixed", top: "95px", left: "157px" }}><img style={{ height: "50px", width: "50px", borderRadius: "50px", border: "1px solid black" }} src={savedImage}></img></div>


                      <div>
                        <div
                          style={{ fontSize: "8px", fontWeight: "bold", marginLeft: "40px", marginTop: "4px" }}
                        >{familyDetail}</div>
                        {familyData.map((data, index) => {

                          return (
                            <div className='row' style={{ fontSize: "6px", display: "flex", margin: "3px", marginLeft: "30px" }} key={index}>
                              <div className='col-4'>{data.label}</div>    <div className='col-8'>:- {data.value}</div>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <div
                          style={{ fontSize: "8px", fontWeight: "bold", marginLeft: "40px", marginTop: "4px" }}
                        >{contactDetail}</div>
                        {contactData.map((data, index) => {

                          return (
                            <div className='row' style={{ fontSize: "6px", display: "flex", margin: "3px", marginLeft: "30px" }} key={index}>
                              <div className='col-4'>{data.label}</div>    <div className='col-8'>:- {data.value}</div>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <div
                          style={{ fontSize: "8px", fontWeight: "bold", marginLeft: "40px", marginTop: "4px" }}
                        >{otherDetail}</div>
                        {otherData.map((data, index) => {
                          return (
                            <div className='row' style={{ fontSize: "6px", display: "flex", margin: "3px", marginLeft: "30px" }} key={index}>
                              <div className='col-4'>{data.label}</div>    <div className='col-8'>:- {data.value}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <form>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                        <img style={{ height: "120px", width: "80px", margin: "5px" }} src={sample1}></img>

                        <label
                          style={{ margin: "" }}
                        >
                          <input
                            type="checkbox"
                            value="page2"
                            checked={selectedPage === 'page2'}
                            onChange={handlePageChange}
                            style={{ margin: "" }}

                          />
                        </label>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", objectFit: "cover" }}>
                        <img style={{ height: "120px", width: "80px", margin: "5px" }} src={sample2}></img>

                        <label
                          style={{ margin: "" }}
                        >
                          <input
                            type="checkbox"
                            value="page1"
                            checked={selectedPage === 'page1'}
                            onChange={handlePageChange}
                            style={{ margin: "" }}

                          />
                        </label>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", objectFit: "cover" }}>
                        <img style={{ height: "120px", width: "80px", margin: "5px" }} src={sample3}></img>

                        <label
                          style={{ margin: "" }}
                        >
                          <input
                            type="checkbox"
                            value="page3"
                            checked={selectedPage === 'page3'}
                            onChange={handlePageChange}
                            style={{ margin: "" }}

                          />
                        </label>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <Link to=""><button onClick={() => selectedPage === "page1" || selectedPage === "page2" ? handleGenerateButtonClick() : handleGenerateButtonClicks()}
                    style={{ backgroundColor: "white", fontSize: "15px", fontWeight: "bold", color: "black", borderRadius: "30px", height: "35px", width: "90px", border: "1px solid #fc825b" }} s>Download</button></Link>
                  <Link to="/biodata-make"><button style={{ backgroundColor: "white", fontSize: "15px", fontWeight: "bold", color: "black", borderRadius: "30px", height: "35px", width: "70px", border: "1px solid #fc825b" }} >Edit</button></Link>

                  <button onClick={() => {
                    setOpens(false);
                  }} style={{ backgroundColor: "white", fontSize: "15px", fontWeight: "bold", color: "black", borderRadius: "30px", height: "35px", width: "70px", border: "1px solid #fc825b" }}>Cancel</button>
                </div>
              </Typography>
            </div>
          </Box>
        }
      </Modal>


      <div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: "100px" }}>

          {alldata.map((img, index) => (
            <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
              <div style={{
                height: '310px',
                width: '260px',
                margin: "10px",
              }}
                className='boxshadow'
              >
                <img
                  style={{
                    height: '290px',
                    width: '240px',
                    cursor: 'pointer',
                    border: index === selectedImage ? '2px solid grey' : 'none',
                    margin: "10px",
                  }}
                  src={img}
                  alt={`template-${index + 1}`}
                /></div>

              <button
                className="selectButton" // Add a class for styling
                onClick={() => showpreview(index)}

              >
                Select
              </button>


            </div>

          ))}
        </div>
      </div>

      <div style={{ height: "50px", textAlign: "center", color: "black", paddingTop: "10px", fontFamily: "cursive", position: window.innerWidth >= "800" ? "relative" : "", bottom: "0", width: "100%" }}>
        Beautifully Handcrafted Marriage Biodata
      </div>
    </div >
  )
}
